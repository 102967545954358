import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/page-header';

import img2 from '../images/img2.jpg';
import img3 from '../images/img3.jpg';

const EducationPage = () => (
  <Layout>
    <SEO title="Propuesta Educativa" />
    <div className="page education-page">
      <PageHeader bg={img2} />
      <div className="wrapper content">
        <h1>Propuesta Educativa</h1>
        <div className="row">
          <div className="col col-50">
            <p>
              En el Instituto Hidalgo esta conciente que el éxito de los alumnos depende en gran medida del maestro ya que él es un agente esencial en el proyecto constante de educar.
            </p>
            <p>
              El Instituto tiene como prioridad la selección y formación de Profesores capaces de transmitir conocimientos de manera significativa, continua y entretenida, dándoles a nuestros alumnos el gusto de aprender y la iniciativa de que lo hagan ellos mismos.
            </p>
            <p>
              Es por esto que el Instituto se enfoca en las áreas siguientes:
            </p>
          </div>
          <div className="col-50 img-container photo">
            <img src={img3} alt=""/>
            <div className="circle" />
            <div className="circle2" />
          </div>
        </div>
        <br/>
        <br/>
        <div className="col">
          <div className="ed-area">
            <h2>1. Método de Enseñanza</h2>
            <p>
              El Instituto Hidalgo ha buscado poco a poco ir adoptando el CONSTRUCTIVISMO como método de enseñanza, ya que a través del mismo se busca que el alumno construya su propio método de aprendizaje, haciendo que el profesor le enseñe a pensar buscando desarrollar en el alumno un conjunto de habilidades cognitivas que les permitan optimizar sus procesos de razonamiento.
            </p>
            <p>
              De esta forma, nuestros profesores están capacitados para asentar e impulsar la autonomía e iniciativa de nuestros alumnos haciendo preguntas que necesitan respuestas muy bien reflexionadas y desafía a que se hagan preguntas entre ellos.
            </p>
            <p>
              Por tal motivo el Instituto Hidalgo ha buscado siempre estar a la vanguardia de la Educación no enseñando simplemente sino ENSEÑANDO PARA APRENDER, haciendo de nuestros alumnos personas críticas y analíticas en su andar por el camino de la vida.
            </p>
          </div>
          <div className="ed-area">
            <h2>2. Comunidad Educativa</h2>
            <p>
              Para el Instituto Hidalgo nuestros ALUMNOS son lo más importante, pero la educación de ellos es "TAREA" de todos los que interactúan con el alumno, es decir, toda la comunidad educativa.
            </p>
            <p>
              La comunidad IHB integrada por <b>Directivos, Personal administrativo, Personal de servicio, Profesores, Padres de Familia</b>, quienes son los motores más fuertes en la educación de sus hijos y los alumnos siendo los entes activos del proceso educativo.
            </p>
            <p>
              Es por esto que nuestro propósito fundamental como Comunidad IHB es unirnos para lograr nuestro objetivo de formar hombres y mujeres exitosos a nivel tanto académico como personal, para que vean en nosotros una herramienta que puedan explorar y no un obstáculo con el que puedan tropezar.
            </p>
          </div>
          <div className="ed-area">
            <h2>3. Área Perceptual</h2>
            <p>
              Los esquemas cognitivos (experiencia previa, memoria), entran en juego en el proceso en la medida en que dirigen la exploración perceptiva. Por tal motivo, para el Instituto es de vital importancia fortalecer esta área ya que esta íntimamente relacionada con el proceso académico e intelectual.
            </p>
          </div>
          <div className="ed-area">
            <h2>4. Área Deportiva</h2>
            <p>
              La Educación Física, considerada como asignatura del Plan de Estudios para la Educación Básica, representa una disciplina pedagógica que contribuye al desarrollo armónico del individuo, mediante la práctica sistemática de la actividad física.
            </p>
          </div>
          <div className="ed-area">
            <h2>5. Área del Lenguaje</h2>
            <p>
              Donde se busca estimular el dominio del lenguaje tanto en expresión oral como escrita.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="quote">
            Con un esfuerzo conjunto formaremos al hombre del mañana
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default EducationPage